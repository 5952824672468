import { DashboardOutlined, FlagOutlined, SettingOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import { useRole } from 'entities/useRole';
import { OrganizationSelect } from 'features/OrganizationSelect';
import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import * as S from './Layout.styled';

type Props = {
  sideBarContent?: React.ReactNode;
};

type MenuItem = Required<MenuProps>['items'][number];

type GetItemParams = {
  label: React.ReactNode;
  key: React.Key;
  icon?: React.ReactNode;
  children?: MenuItem[];
  onClick?: VoidFunction;
};

function getItem({ label, key, icon, children, onClick }: GetItemParams): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    onClick,
  } as MenuItem;
}

const ClientsItem = getItem({
  label: <S.MenuLink to={'/clients'}>All users</S.MenuLink>,
  key: 'clients',
  icon: <DashboardOutlined />,
});
const DashboardItem = getItem({
  label: <S.MenuLink to={'/dashboard'}>Dashboard</S.MenuLink>,
  key: 'dashboard',
  icon: <DashboardOutlined />,
});
const MonitoringItem = getItem({
  label: <S.MenuLink to={'/monitoring'}>Monitoring</S.MenuLink>,
  key: 'monitoring',
  icon: <FlagOutlined />,
});
const ChangePasswordItem = getItem({
  label: <S.MenuLink to={'/settings'}>Change password</S.MenuLink>,
  key: 'settings',
  icon: <SettingOutlined />,
});
const TeamSettingsItem = getItem({
  label: <S.MenuLink to={'/team-settings'}>Team settings</S.MenuLink>,
  key: 'team-settings',
  icon: <SettingOutlined />,
});

export const Layout: React.FC<PropsWithChildren<Props>> = (props) => {
  const location = useLocation();
  const role = useRole();

  const defaultSelected = useMemo(() => {
    switch (location.pathname) {
      case '/clients': {
        return ['clients'];
      }
      case '/dashboard': {
        return ['dashboard'];
      }
      case '/monitoring': {
        return role == 'superuser' ? ['monitoring'] : ['dashboard'];
      }
      case '/settings': {
        return ['settings'];
      }
      case '/team-settings': {
        return ['superuser', 'admin'].includes(role ?? 'user') ? ['team-settings'] : ['dashboard'];
      }
    }
    return ['dashboard'];
  }, [location.pathname, role]);

  const items = useMemo(() => {
    if (role === 'superuser') {
      return [DashboardItem, ClientsItem, MonitoringItem, ChangePasswordItem, TeamSettingsItem];
    }
    // TODO
    // else if (role === 'admin') {
    //   return [DashboardItem, ClientsItem, ChangePasswordItem, TeamSettingsItem];
    // }
    // return [DashboardItem, ClientsItem, ChangePasswordItem];
    return [ClientsItem, ChangePasswordItem];
  }, [role]);

  const navigationContent = useMemo(
    () => props.sideBarContent || <S.MenuContainer selectedKeys={defaultSelected} mode="inline" items={items} />,
    [props.sideBarContent, defaultSelected, items],
  );

  return (
    <S.Container>
      <S.Sidebar theme={'light'} zeroWidthTriggerStyle={{}}>
        {navigationContent}
        <S.LogoutButton />
      </S.Sidebar>

      <S.Wrapper>
        <S.Header theme={'light'}>
          <OrganizationSelect />
        </S.Header>
        <S.Content>{props.children}</S.Content>
        <S.Footer>.</S.Footer>
      </S.Wrapper>
    </S.Container>
  );
};
